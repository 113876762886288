<script>
	export let data;
	export let isCompleted;
	export let isFailed;
	export let name;
</script>


<div class="message-content ai">
	{#if !isCompleted && !isFailed}
		<div class="message-wrapper">
			<div class="description">{name}</div>
			<div class="answer">
				<div class="answer-body">{@html data.answer}</div>
			</div>
		</div>
	{/if}


	{#if isCompleted }
	<p style="color:green">{@html data.answer}</p>
	{/if}
	{#if isFailed }
	<p style="color:red">{@html data.answer}</p>
	{/if}
</div>


