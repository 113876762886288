﻿import Admin from './Admin.svelte';

const app = new Admin({
	target: document.body,
	props: {
		name: 'HD Bot'
	}
});

export default app;
