﻿export default {
    English: {
        "login.title": "Sign in to your account",
        "login.email": "Email address *",
        "login.password": "Password *",
        "login.error": "Invalid email or password",
        "login.signin": "Sign in",
        "login.theme": "Theme",
        "login.password.placeholder": "Your Password",
        "login.logout": "Logout",
        "menu.title": "Experiential AI: Embrace Immersive Learning",
        "menu.description": "Step into a new phase of learning, where practice is the key. Engage in captivating experiences to expand your understanding, safely role- play, and apply your skills. Immerse yourself in interactive learning driven by hands- on practice and a passion for growth.",
        "chat.conversation": "Conversation",
        "chat.newChat": "New Chat",
        "chat.unfortunately": "<p style='color:red;'><b>Unfortunately</b>, you have not successfully completed the Qualifier Round.<br> Please begin again and try to learn as much as you can about Charlie, such as what he likes about his motorcycle, his riding interests, and his buying motivations. Your goal is to provide enough value that he agrees to look at a new 2024 motorcycle.</p>",
        "chat.congratulations": "<p style='color:green;'><b>Congratulations!</b> <br> You have successfully completed the Qualifier Round and are eligible to move on to the Preliminary Round. Please remember what you learned about Charlie so you can use the information as you continue in the competition.</p>",
        "stage.notstarted": "Not Started",
        "stage.completed": "Completed",
        "stage.inprogress": "In Progress",
        "stage.failed": "Failed",
        "stage.finish": "Finish",
        "stage.success": "Success",
        "stage.inform": "Information Gathering",
        "modal.title": "Please Select your language",
        "modal.close": "Confirm",
        "message.placeholder": "Type anything..."

    },
    French: {
        "login.title": "Connectez-vous à votre compte",
        "login.email": "Adresse e-mail *",
        "login.password": "Mot de passe *",
        "login.error": "Adresse e-mail ou mot de passe invalide",
        "login.signin": "Se connecter",
        "login.theme": "Thème",
        "login.password.placeholder": "Votre mot de passe",
        "login.logout": "Se déconnecter",
        "menu.title": "IA expérientielle: adoptez l'apprentissage immersif",
        "menu.description": "Entrez dans une nouvelle phase d'apprentissage, où la pratique est la clé. Plongez dans des expériences captivantes pour élargir votre compréhension, jouez en toute sécurité et appliquez vos compétences. Plongez-vous dans un apprentissage interactif basé sur la pratique et une passion pour la croissance.",
        "chat.conversation": "Conversation",
        "chat.newChat": "Nouveau chat",
        "chat.unfortunately": "<p style='color:red;'><b>Malheureusement</b>,vous n'avez pas réussi l'épreuve de qualification. Recommencez et essayez d'en apprendre le plus possible sur Charlie, notamment sur ce qu'il affectionne à propos de sa moto, ses intérêts en matière de conduite et ses motivations d'achat. Votre objectif est de lui apporter suffisamment de valeur ajoutée pour qu'il accepte de regarder une nouvelle moto 2024.</p>",
        "chat.congratulations": "<p style='color:green;'><b>Félicitations!</b><br> Vous avez réussi l'épreuve de qualification et vous pouvez passer à l'épreuve préliminaire. Rappelez-vous ce que vous avez appris sur Charlie afin de pouvoir utiliser ces informations lors de la suite du concours.</p>",
        "stage.notstarted": "Pas commencé",
        "stage.completed": "Terminé",
        "stage.inprogress": "En cours",
        "stage.failed": "Échoué",
        "stage.finish": "Terminer",
        "stage.success": "Succès",
        "stage.inform": "Collecte d'informations",
        "modal.title": "Sélectionnez la langue",
        "modal.close": "Fermer",
        "message.placeholder": "Tapez quelque chose..."
    },
    Spanish: {
        "login.title": "Inicia sesión en tu cuenta",
        "login.email": "Dirección de correo electrónico *",
        "login.password": "Contraseña *",
        "login.error": "Correo electrónico o contraseña no válidos",
        "login.signin": "Iniciar sesión",
        "login.theme": "Tema",
        "login.password.placeholder": "Tu contraseña",
        "login.logout": "Cerrar sesión",
        "menu.title": "IA experiencial: abraza el aprendizaje inmersivo",
        "menu.description": "Ingresa a una nueva fase de aprendizaje, donde la práctica es la clave. Participa en experiencias cautivadoras para ampliar tu comprensión, juega de manera segura y aplica tus habilidades. Sumérgete en un aprendizaje interactivo basado en la práctica y una pasión por el crecimiento.",
        "chat.conversation": "Conversación",
        "chat.newChat": "Nuevo chat",
        "chat.unfortunately": "<p style='color:red;'><b>Lamentablemente,</b> no ha completado con éxito la Ronda de Clasificación. Por favor, comience de nuevo e intente aprender todo lo posible sobre Charlie, como lo que le gusta de su motocicleta, sus intereses en la conducción y sus motivaciones de compra. Su objetivo es proporcionar suficiente valor para que esté de acuerdo en mirar una nueva motocicleta de 2024.</p>",
        "chat.congratulations": "<p style='color:green;'><b>¡Felicidades!</b> <br> Ha completado con éxito la Ronda de Clasificación y es elegible para pasar a la Ronda Preliminar. Por favor, recuerde lo que aprendió sobre Charlie para que pueda utilizar la información mientras continúa en la competencia.</p>",
        "stage.notstarted": "No iniciado",
        "stage.completed": "Completado",
        "stage.inprogress": "En progreso",
        "stage.failed": "Fallido",
        "stage.finish": "Terminar",
        "stage.success": "Éxito",
        "stage.inform": "Recopilación de información",
        "modal.title": "Seleccionar idioma",
        "modal.close": "Cerrar",
        "message.placeholder": "Escribe algo..."
    },
    Portuguese: {
        "login.title": "Faça login na sua conta",
        "login.email": "Endereço de e-mail *",
        "login.password": "Senha *",
        "login.error": "E-mail ou senha inválidos",
        "login.signin": "Entrar",
        "login.theme": "Tema",
        "login.password.placeholder": "Sua senha",
        "login.logout": "Sair",
        "menu.title": "IA Experiencial: Abraçar a Aprendizagem Imersiva",
        "menu.description": "Entre em uma nova fase de aprendizado, onde a prática é a chave. Participe de experiências cativantes para expandir sua compreensão, jogue com segurança e aplique suas habilidades. Mergulhe em um aprendizado interativo baseado na prática e paixão pelo crescimento.",
        "chat.conversation": "Conversa",
        "chat.newChat": "Nova conversa",
        "chat.unfortunately": "<p style='color:red;'><b>Infelizmente,</b> você não completou com sucesso a Rodada de Qualificação. Por favor, comece novamente e tente aprender o máximo possível sobre o Charlie, como o que ele gosta em sua motocicleta, seus interesses em pilotar e suas motivações de compra. Seu objetivo é fornecer valor suficiente para que ele concorde em dar uma olhada em uma nova motocicleta de 2024.</p>",
        "chat.congratulations": "<p style='color:green;'><b>Parabéns!</b> <br> Você completou com sucesso a Rodada de Qualificação e está elegível para avançar para a Rodada Preliminar. Por favor, lembre-se do que aprendeu sobre Charlie para que possa usar as informações enquanto continua na competição.</p>",
        "stage.notstarted": "Não Iniciado",
        "stage.completed": "Concluído",
        "stage.inprogress": "Em Progresso",
        "stage.failed": "Falhou",
        "stage.finish": "Terminar",
        "stage.success": "Sucesso",
        "stage.inform": "Coleta de Informações",
        "modal.title": "Selecione o idioma",
        "modal.close": "Fechar",
        "message.placeholder": "Digite algo..."
    },
    Italian: {
        "login.title": "Accedi al tuo account",
        "login.email": "Indirizzo email *",
        "login.password": "Password *",
        "login.error": "Email o password non valide",
        "login.signin": "Accedi",
        "login.theme": "Tema",
        "login.password.placeholder": "La tua password",
        "login.logout": "Disconnettersi",
        "menu.title": "IA esperienziale: abbraccia l'apprendimento immersivo",
        "menu.description": "Entra in una nuova fase di apprendimento, dove la pratica è la chiave. Partecipa a esperienze coinvolgenti per ampliare la tua comprensione, gioca in modo sicuro e applica le tue abilità. Immergiti in un apprendimento interattivo basato sulla pratica e sulla passione per la crescita.",
        "chat.conversation": "Conversazione",
        "chat.newChat": "Nuova chat",
        "chat.unfortunately": "<p style='color:red;'> Purtroppo non hai completato con successo il Turno di Qualificazione. Ti preghiamo di riprovare e di cercare di imparare il più possibile su Charlie, ad esempio cosa gli piace della sua motocicletta, i suoi interessi di guida e le sue motivazioni d'acquisto. Il tuo obiettivo è quello di fornire un valore sufficiente per convincerlo a comprare una nuova motocicletta del 2024.</p>",
        "chat.congratulations": "<p style='color:green;'><b>Congratulazioni!</b><br> Hai completato con successo il Turno di Qualificazione e puoi passare al Turno Preliminare. Ricorda le informazioni che hai appreso su Charlie in modo da poterle utilizzare nel corso della competizione.</p>",
        "stage.notstarted": "Non Iniziato",
        "stage.completed": "Completato",
        "stage.inprogress": "In Corso",
        "stage.failed": "Fallito",
        "stage.finish": "Finire",
        "stage.success": "Successo",
        "stage.inform": "Raccolta di Informazioni",
        "modal.title": "Seleziona la lingua",
        "modal.close": "Chiudi",
        "message.placeholder": "Digita qualcosa..."
    },
    German: {
        "login.title": "Melden Sie sich bei Ihrem Konto an",
        "login.email": "E-Mail-Adresse *",
        "login.password": "Passwort *",
        "login.error": "Ungültige E-Mail-Adresse oder Passwort",
        "login.signin": "Anmelden",
        "login.theme": "Thema",
        "login.password.placeholder": "Ihr Passwort",
        "login.logout": "Ausloggen",
        "menu.title": "Erlebnis-KI: Eintauchen in das immersive Lernen",
        "menu.description": "Betreten Sie eine neue Phase des Lernens, in der die Praxis der Schlüssel ist. Tauchen Sie ein in fesselnde Erfahrungen, um Ihr Verständnis zu erweitern, sicher zu spielen und Ihre Fähigkeiten anzuwenden. Tauchen Sie ein in interaktives Lernen, das von praktischer Übung und Leidenschaft für Wachstum getrieben wird.",
        "chat.conversation": "Gespräch",
        "chat.newChat": "Neuer Chat",
        "chat.unfortunately": "<p style='color:red;'><b> Leider haben Sie die Qualifikationsrunde nicht erfolgreich abgeschlossen.</b> Bitte beginnen Sie erneut und versuchen Sie, so viel wie möglich über Charlie zu erfahren, wie zum Beispiel, was ihm an seinem Motorrad gefällt, seine Interessen am Fahren und seine Kaufmotivationen. Ihr Ziel ist es, genug Wert zu bieten, damit er zustimmt, sich ein neues Motorrad von 2024 anzusehen.</p>",
        "chat.congratulations": "<p style='color:green;'><b>Herzlichen Glückwunsch!</b><br> Sie haben erfolgreich die Qualifikationsrunde abgeschlossen und sind berechtigt, zur Vorrunde überzugehen. Bitte denken Sie daran, was Sie über Charlie gelernt haben, damit Sie die Informationen nutzen können, während Sie den Wettbewerb fortsetzen.</p>",
        "stage.notstarted": "Nicht begonnen",
        "stage.completed": "Abgeschlossen",
        "stage.inprogress": "In Bearbeitung",
        "stage.failed": "Gescheitert",
        "stage.finish": "Beenden",
        "stage.success": "Erfolg",
        "stage.inform": "Informationssammlung",
        "modal.title": "Sprache auswählen",
        "modal.close": "Schließen",
        "message.placeholder": "Tippen Sie etwas ein..."
    },
    Korean: {
        "login.title": "계정에 로그인",
        "login.email": "이메일 주소 *",
        "login.password": "암호 *",
        "login.error": "잘못된 이메일 또는 암",
        "login.signin": "로그인",
        "login.theme": "테마",
        "login.password.placeholder": "비밀번호",
        "login.logout": "로그 아웃",
        "menu.title": "체험형 AI: 몰입형 학습 채택",
        "menu.description": "실습이 핵심인 새로운 학습 단계로 들어가세요. 이해를 확장하기 위해 매력적인 경험에 참여하고 안전하게 롤플레이하고 기술을 적용하세요. 실습과 성장에 대한 열정에 기반한 대화형 학습에 몰입하세요.",
        "chat.conversation": "대화",
        "chat.newChat": "새로운 대화",
        "chat.unfortunately": "<p style='color:red;'> 안타깝게도, 대표 선발 라운드를 성공적으로 완료하지 못했습니다. 다시 시작하여 찰리에 대해 가능한 한 많이 알아보십시오. 예를 들어 그가 자신의 오토바이에 대해 무엇을 좋아하는지, 그의 라이딩 관심사 및 구매 동기에 대한 정보를 알아보세요. 여러분의 목표는 충분한 가치를 제공하여 그가 2024년식 새로운 오토바이를 보겠다고 동의하게 하는 것입니다.</p>",
        "chat.congratulations": "<p style='color:green;'>축하합니다! 대표 선발 라운드를 성공적으로 마치셨으며 본선 예선 라운드로 진출할 자격을 부여받으셨습니다. 대회를 계속 진행하면서 찰리에 대해 배운 것을 기억하여 정보를 활용하십시오.</p>",
        "stage.notstarted": "시작되지 않음",
        "stage.completed": "완료됨",
        "stage.inprogress": "진행 중",
        "stage.failed": "실패함",
        "stage.finish": "완료",
        "stage.success": "성공",
        "stage.inform": "정보 수집",
        "modal.title": "언어 선택",
        "modal.close": "닫기",
        "message.placeholder": "무언가를 입력하세요..."
    },
    Thai: {
        "login.title": "เข้าสู่ระบบบัญชีของคุณ",
        "login.email": "ที่อยู่อีเมล *",
        "login.password": "รหัสผ่าน *",
        "login.error": "อีเมลหรือรหัสผ่านไม่ถูกต้อง",
        "login.signin": "เข้าสู่ระบบ",
        "login.theme": "ธีม",
        "login.password.placeholder": "รหัสผ่านของคุณ",
        "login.logout": "ออกจากระบบ",
        "menu.title": "AI ประสบการณ์: ยอมรับการเรียนรู้แบบมีสมอง",
        "menu.description": "เข้าสู่ระยะการเรียนรู้ใหม่ที่ฝึกฝนเป็นสิ่งสำคัญ มุ่งมั่นในประสบการณ์ที่น่าตื่นตาตื่นใจเพื่อขยายความเข้าใจของคุณ สนุกไปกับประสบการณ์ที่น่าตื่นตาตื่นใจเพื่อขยายความเข้าใจของคุณ สนุกไปกับประสบการณ์ที่น่าตื่นตา",
        "chat.conversation": "การสนทนา",
        "chat.newChat": "แชทใหม่",
        "chat.unfortunately": "<p style='color:red;'>น่าเสียดายที่คุณไม่สามารถผ่านรอบคัดเลือกได้สำเร็จ โปรดเริ่มใหม่และพยายามเรียนรู้เกี่ยวกับชาร์ลีมากที่สุดเท่าที่จะเป็นไปได้ เช่น สิ่งที่เขาชอบในมอเตอร์ไซค์ของเขา, ความสนใจในการขับขี่ของเขาและแรงจูงใจในการซื้อ จุดมุ่งหมายของคุณคือการให้ค่ามากพอที่เขาจะยอมมองมอเตอร์ไซค์รุ่นใหม่ปี 2024</p>",
        "chat.congratulations": "<p style='color:green;'> ขอแสดงความยินดี! คุณผ่านรอบคัดเลือกสำเร็จแล้วและมีสิทธิ์ที่จะเข้าสู่รอบต่อไปได้ โปรดจำข้อมูลเกี่ยวกับ ชาร์ลีไว้ด้วย เพื่อให้คุณสามารถใช้ข้อมูลได้เมื่อคุณดำเนินการต่อในการแข่งขัน</p>",
        "stage.notstarted": "ยังไม่เริ่ม",
        "stage.completed": "เสร็จสิ้น",
        "stage.inprogress": "กำลังดำเนินการ",
        "stage.failed": "ล้มเหลว",
        "stage.finish": "เสร็จสิ้น",
        "stage.success": "สำเร็จ",
        "stage.inform": "การรวบรวมข้อมูล",
        "modal.title": "เลือกภาษา",
        "modal.close": "ปิด",
        "message.placeholder": "พิมพ์อะไรบางอย่าง..."
    },
    Japanese: {
        "login.title": "アカウントにサインイン",
        "login.email": "メールアドレス *",
        "login.password": "パスワード *",
        "login.error": "無効なメールアドレスまたはパスワード",
        "login.signin": "サインイン",
        "login.theme": "テーマ",
        "login.password.placeholder": "パスワード",
        "login.logout": "ログアウト",
        "menu.title": "体験型AI：没入型学習を取り入れる",
        "menu.description": "実践が鍵となる新しい学習段階に入ります。理解を広げるために魅力的な体験に参加し、安全にロールプレイし、スキルを適用します。実践と成長への情熱に基づく対話型学習に没入してください。",
        "chat.conversation": "会話",
        "chat.newChat": "新しいチャット",
        "chat.unfortunately": "<p style='color:red;'>残念ながら、予選ラウンドを通過することができませんでした。チャーリーについてより多くのことを学び、彼が自分のバイクで好きなこと、彼のライディングに対する関心や購買動機についてもっと学んでみましょう。あなたの目標は、お客様が新しい2024年モデルのバイクを検討することに十分な価値を提供することです。</p>",
        "chat.congratulations": "<p style='color:green;'>おめでとうございます！予選ラウンドを無事に完了し、予選ラウンドに進む資格を獲得しました。チャーリーについて学んだことを思い出して、コンテスト時にその情報を活用できるようにしてください。</p>",
        "stage.notstarted": "開始されていません",
        "stage.completed": "完了",
        "stage.inprogress": "進行中",
        "stage.failed": "失敗しました",
        "stage.finish": "終了",
        "stage.success": "成功",
        "stage.inform": "情報収集",
        "modal.title": "言語を選択",
        "modal.close": "閉じる",
        "message.placeholder": "何か入力してください..."
    },
    Chinese: {
        "login.title": "登录到您的帐户",
        "login.email": "电子邮件地址 *",
        "login.password": "密码 *",
        "login.error": "无效的电子邮件或密码",
        "login.signin": "登录",
        "login.theme": "主题",
        "login.password.placeholder": "您的密码",
        "login.logout": "登出",
        "menu.title": "体验式AI：拥抱沉浸式学习",
        "menu.description": "进入一个新的学习阶段，其中实践是关键。参与引人入胜的体验，以扩展您的理解，安全地扮演角色并应用您的技能。沉浸在基于实践和对成长的热情驱动的互动学习中。",
        "chat.conversation": "对话",
        "chat.newChat": "新聊天",
        "chat.unfortunately": "<p style='color:red;'>很遗憾，您未能成功完成预选赛。请重新开始，并尽量了解查理的情况，例如他对摩托车的喜好、骑行兴趣和购买动机。您的目标是提供足够的价值，让他同意看看新的2024款摩托车。</p>",
        "chat.congratulations": "<p style='color:green;'>恭喜您！您已成功完成预选赛，有资格进入初赛。请记住你们学到的关于查理的知识，以便在继续比赛时使用这些信息。</p>",
        "stage.notstarted": "未开始",
        "stage.completed": "已完成",
        "stage.inprogress": "进行中",
        "stage.failed": "失败",
        "stage.finish": "完成",
        "stage.success": "成功",
        "stage.inform": "信息收集",
        "modal.title": "选择语言",
        "modal.close": "关闭",
        "message.placeholder": "输入任何内容..."
    }
};
