<script>
	export let message;
	export let addedPoints;
	export let feedback;
	export let stage
	export let status
	export function setData(data) {
		addedPoints = data.addedPoints;
		feedback = data.feedback;
		stage = data.stage;
		status = data.status;
	}
</script>

<div class="message-content user">
	<div class="message-wrapper">
		<div class="description">You</div>
		<div class="answer">
			<div class="answer-body">{@html message}</div>
			{#if feedback !== undefined}<div class="feedback">{feedback}</div>{/if}
		</div>
	</div>
	
</div>